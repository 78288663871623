/**
 * @section Base Styles
 */

/* Fonts */
@font-face {
    font-family: 'Antenna Medium';
    font-style: normal;
    font-weight: normal;
    src: url('font/Antenna_Medium/Antenna-Medium.ttf');
}

* {
    font-family: 'Antenna Medium';
}


body {
    padding: 50px;
    @media screen and (max-width: 767px) {
        padding: 0;
    }
}
*:focus {
    outline: none;
}
input,
textarea,
select,
button {
    font: inherit;
    font-family: 'Antenna Medium';
}

.logo__svg {
    fill: #002aff;
    svg {
        width: 100px;
        height: 56px;
    }
}

.logo-ragu,
.logo-communication {
    fill: #002aff;
}

h1 {
    margin: 50px 0;
}
