/**
 * @section Buttons modify
 */

.button--top {
    display: flex;
    flex-direction: column;
    // max-width: 600px;
    // margin: 0 auto;
}
.buttons-modify {
    margin: 20px 0;
    z-index: 5;

    button {
        padding: 5px 10px;
        border-radius: 0;
        cursor: pointer;

        &:first-child {
            margin-left: 0;
        }
    }
    &--reset {
        margin: 0 !important;
        border-bottom: 0;
        svg {
            width: 55px;
        }
        button {
            //  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
        }
    }
    &--text {
        margin-bottom: 30px;

        input {
            margin: 0;
            box-shadow: none;
            border-radius: 0;
            &::placeholder {
                color: gray;
                opacity: 1;
            }
        }
        button {
            // box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
        }
    }
    &--download {
        position: relative;
        display: none;
        align-items: center;
        justify-content: center;
        width: 100%;
        max-width: 600px;
        margin: 0 auto;
        margin-top: 200px;
        button {
            background: #0000ff;
            color: white;
            border-radius: 40px;
            height: 70px;
            width: 200px;
        }
        @media screen and (max-width: 767px) {
            margin-top: -33px;
        }
        &.flex {
            display: flex;
            justify-content: space-between;
            @media screen and (max-width: 767px) {
                flex-wrap: wrap;
            }
        }
        button {
            // ow: 0 8px 15px rgba(0, 0, 0, 0.1);
            padding-top: 10px;
            padding-bottom: 10px;
            @media screen and (max-width: 767px) {
                margin-bottom: 40px;
            }
        }
        .content-share {
            .share-socials {
                transition: .5s opacity;
                position: absolute;
                opacity: 1;
                top: 0;
                right: 0;
                &.hide {
                    opacity: 0;
                }
            }
            #share {
                transition: .5s opacity;
                display: none;
                opacity: 0;
                position: absolute;
                top: 7px;
                right: 0;
                &.view {
                    opacity: 1;
                }
            }
        }
    }
    &--assets {
        display: flex;
        align-items: flex-start;
        align-content: center;
        flex-direction: column;
        @media screen and (max-width: 1192px) {
            left: -40px;
        }
        @media screen and (max-width: 991px) {
            left: -104px;
        }
        @media screen and (max-width: 767px) {
            left: -133px;
            top: 67%;
        }

        button {
            // box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
            background: none;
            svg {
                width: 55px;
            }
        }
    }
    &--color {
        // padding-right: 50px;
        display: flex;
        align-items: flex-start;
        align-content: center;

        flex-direction: column;
        @media screen and (max-width: 1192px) {
            right: -100px;
            top: 55%;
        }
        @media screen and (max-width: 991px) {
            right: -140px;
        }
        @media screen and (max-width: 767px) {
            right: -150px;
            top: 67%;
        }

        p {
            margin-bottom: 0;
        }
        button {
            width: 55px;
            height: 55px;
            border: 0;
            border-radius: 50%;
            // box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
            transition: 0.3s ease;
            @media screen and (max-width: 991px) {
                width: 30px;
                height: 30px;
            }
            &.active {
                width: 60px;
                height: 60px;
                @media screen and (max-width: 991px) {
                    width: 45px;
                    height: 45px;
                }
            }
            &.change-color__white {
                background: #ffffff;
            }
            &.change-color__black {
                background: #000;
            }
            &.change-color__violet {
                background: #a600a8;
            }
            &.change-color__blue {
                background: #0000ff;
            }
            &.change-color__red {
                background: #ff0e51;
            }
            &.change-color__azure {
                background: #17bdbd;
            }
        }
    }
    /*    .share-text {
            margin-right: 15px;
        }*/
}

.change-assets {
    transition: 0.3s ease;
    &.active {
        color: white;
    }
}

#defaultCanvas0 {
    transform: scale(0.3);
    margin-top: 200px;
    @media screen and (max-width: 991px) {
        right: -140px;
        transform: scale(0.25);
        margin-top: 200px;
        margin-top: 0;
    }
    @media screen and (max-width: 767px) {
        transform: scale(0.13);
    }
}

.content-form-image {
    height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 991px) {
        height: 100vh;
        margin-top: 150px;
    }
    @media screen and (max-width: 767px) {
        margin-top: 20px;
        padding-left: 20px;
        padding-right: 20px;
    }
}

.buttons-modify--reset {
    // margin-bottom: 30px;
    // position: absolute;
    // top: -120px;
    .reset {
        padding: 0;
        margin: 0;
        display: flex;
        align-items: center;
    }
}

.buttons-modify--text {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 30px;
    input {
        height: 54px;
        width: 450px;
    }
}

.set-text {
    width: 55px;
    height: 55px;
    color: white;
    background: #00f;
    border-radius: 50% !important;
}
#form-upload {
}

.introText {
    max-width: 600px;
    margin: 0 auto;
    text-align: center;
    margin-top: 60px;
    margin-bottom: 100px;
    @media screen and (max-width: 767px) {
        padding-left: 20px;
        padding-right: 20px;
    }
}

.logo {
    display: flex;
    justify-content: center;
    svg {
        width: 300px;
        height: auto;
    }
}

.wrapperAll--styles {
    max-width: 600px;
    margin: 0 auto;
    width: 100%;
    @media screen and (max-width: 767px) {
        padding-left: 20px;
        padding-right: 20px;
    }
}

.upload--img {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
}

.toggle--div {
    display: flex;
    justify-content: space-between;

    width: 100%;
    display: none;
    margin-top: 27px;
}

.trigger {
    text-transform: uppercase;
    margin-bottom: 0;
    border-bottom: 2px solid gray;
    padding-bottom: 15px;
    width: 100%;

    color: #00f;
}

.buttons-modify--download {
}
