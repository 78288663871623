/**
 * @section File upload
 */

#file-upload {
  display: none;
}

.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  width: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  height: 70px;
  border-radius: 45px;
  background: #00f;
  color: white;
  text-transform: uppercase;
  @media screen and (max-width: 767px) {
    width: 100%;
    margin: 0 auto;
  }

  svg {
    width: 50px;
    margin-top: 11px;
    margin-right: 20px;
  }
  span {
    display: flex;
    align-content: center;
    align-items: center;
    &.upload-text {
      display: flex;
      align-items: center;
    }
  }

  &.upload-file {
  }

  &.input-loading {
    background: lightblue;
  }
  &.input-loaded {
    background: lightseagreen;
  }
  &.input-error {
    background: lightcoral;
  }
}

/* Loader */
.loader {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  filter: contrast(20);
  &.flex {
    display: flex;
  }

  .loader-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .dot {
    position: absolute;
    width: 16px;
    height: 16px;
    top: 12px;
    left: 54px;
    filter: blur(4px);
    background: #303952;
    border-radius: 50%;
    transform: translateX(0);
    animation: dot 2.8s infinite;
  }
  .dots {
    transform: translateX(0);
    margin-top: 12px;
    margin-left: 70px;
    animation: dots 2.8s infinite;
    span {
      display: block;
      float: left;
      width: 16px;
      height: 16px;
      margin-left: 16px;
      filter: blur(4px);
      background: #303952;
      border-radius: 50%;
    }
  }
}

@keyframes dot {
  50% {
    transform: translateX(96px);
  }
}
@keyframes dots {
  50% {
    transform: translateX(-31px);
  }
}
/**/

.img_left {
  position: absolute;
  left: 0;
  top: 0;
  @media screen and (max-width: 1680px) {
    width: 400px;
  }
  @media screen and (max-width: 1440px) {
    width: 350px;
  }
  @media screen and (max-width: 1024px) {
    width: 250px;
  }
  @media screen and (max-width: 768px) {
    width: 150px;
  }
  @media screen and (max-width: 768px) {
    display: none;
  }
}

.img_right {
  position: absolute;
  right: 0;
  top: 0;
  @media screen and (max-width: 1680px) {
    width: 400px;
  }
  @media screen and (max-width: 1440px) {
    width: 350px;
  }
  @media screen and (max-width: 1024px) {
    width: 250px;
  }
  @media screen and (max-width: 768px) {
    display: none;
  }
}

.img_top {
  display: none;
  @media screen and (max-width: 768px) {
    display: block;
  }
  width: 100%;
}
